import { Component, OnInit } from "@angular/core";
import { SharedClassComponent } from "src/app/configurations/shared-class/shared-class.component";
import { confirm } from "devextreme/ui/dialog";

@Component({
  selector: "app-employer-details-change",
  templateUrl: "./employer-details-change.component.html",
  styleUrls: ["./employer-details-change.component.scss"],
})
export class EmployerDetailsChangeComponent
  extends SharedClassComponent
  implements OnInit
{
  employerInfo: any;
  titile: string = "EMPLOYER DETAILS CHANGE";
  allowUpdate: boolean = true;
  selectedRegionalId: number;
  selectedDistrictId: number;
  selectedShehiaId: number;

  districtList = [];
  shehiaList = [];
  regionList = [];
  bankList = [];
  districtListNew: any = [];
  shehiaListNew: any = [];
  employerID: string="";

  ngOnInit() {
    const userDetails = this.authService.getUserDetails();

    if (userDetails.linkId && userDetails.linkId != "") {
      this.employerNoSearch(userDetails.linkId);
    }
  
    this.getDistricts();
    // EmployerID
    this.shehia();
    this.getRegion();
    this.getEmployerDetailsChange();
  }

  
  employerNoSearch(empNo) {  
    const data = {
      requestType: "EMPLOYERS_GET",
      employerNumber: empNo,
    };

    this.utilities.postServiceCallNew(data).subscribe(
      (res) => {

        const response = res.json();
        if (response.code == 2000) {
          this.employerID = response.data.EmployerID;
          if(this.employerID){
            this.getEmployerDetailsChange();
          }
    

        } else {
          this.toastr.error(response.message, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }


  getEmployerDetailsChange() {
    const data = {
      requestType: "EMPLOYER_DETAILS_CHANGE_GET",
      // employerId: "04CC7A31-8503-46AC-904F-FD3B73EA35FF",
      employerId: this.employerID
    };
    this.spinner.show();
    this.utilities.postServiceCallNew(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.code == 2000) {
          this.employerInfo = response.data;
          this.selectedRegionalId = Number(response.data.RegionID);
          if (this.selectedRegionalId) {
            this.filterDistricts(Number(this.selectedRegionalId));
          }
          this.selectedDistrictId = Number(response.data.DistrictID);

          this.filterShehia(Number(this.selectedDistrictId));

          this.selectedShehiaId = Number(response.data.ShehiaID);
        } else {
          this.toastr.error(response.message, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  allowFormUpdate() {
    this.allowUpdate = false;
  }

  employerUpdate() {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const phonePattern = /^[0-9]{10}$/;
    const urlPattern = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;

    if (this.employerInfo.TelephoneMobile && !phonePattern.test(this.employerInfo.TelephoneMobile)) {

      this.toastr.error("Invalid Telephone Office Number. Please provide a valid 10-digit phone number.", "Error");
  
      return;
    }

    if (this.employerInfo.TelephoneOffice && !phonePattern.test(this.employerInfo.TelephoneOffice)) {

      this.toastr.error("Invalid Telephone Mobile Number. Please provide a valid 10-digit phone number.", "Error");
  
      return;
    }
  

    if (this.employerInfo.Website && !urlPattern.test(this.employerInfo.Website)) {

      this.toastr.error("Invalid Website. Please provide a valid Website Url.", "Error");
  
      return;
    }
  
  
    if (this.employerInfo.EmailAddress && !emailPattern.test(this.employerInfo.EmailAddress)) {
      this.toastr.error("Invalid email address. Please provide a valid email.", "Error");

      return;
    }
    const result = confirm(
      "Do you really want to Save changes.",
      "Update Employer Infomation"
    );
    result.then((dialogResult) => {
      console.log(dialogResult);



      if (!dialogResult) {
        this.allowUpdate = true;
        return;
      }

      const data = {
        requestType: "EMPLOYER_DETAILS_CHANGE_UPDATE",
        // employerId: "04CC7A31-8503-46AC-904F-FD3B73EA35FF",
         employerId: this.employerID,
        AlternateName: this.employerInfo.AlternateName,
        PostalAddress: this.employerInfo.PostalAddress,
        PhysicalAddress: this.employerInfo.PhysicalAddress,
        EmailAddress: this.employerInfo.EmailAddress,
        Website: this.employerInfo.Website,
        TelephoneOffice: this.employerInfo.TelephoneOffice,
        TelephoneMobile: this.employerInfo.TelephoneMobile,

        RegionID: this.selectedRegionalId,
        DistrictID: this.selectedDistrictId,
        ShehiaID: this.selectedShehiaId,
      };

      this.spinner.show();
      // call utilities' service postservicecall to submit registration data to server.
      this.utilities.postServiceCallNew(data).subscribe(
        (res) => {
          const response = res.json();
          this.spinner.hide();
          if (response.code == 2000) {
            this.allowUpdate = true;

            this.getEmployerDetailsChange();
            this.toastr.success("Employer updated Succesfull, Please wait for Admin Approval");
            this.paramswinpnl = false;
          } else {
            // window.location.href = "memberregistration:"  + "&" + AppSettings.baseURL;
            this.toastr.error(response.message, "Error msg");
          }
          this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();
          this.toastr.error(
            "Something went wrong, please try again",
            "Request Failed"
          );
        }
      );
    });
  }

  filterDistricts(e) {
    this.districtListNew = this.districtList.filter((res) => res.RegionID == e);
    this.selectedRegionalId = e;
  }

  filterShehia(e) {
    
    this.shehiaListNew = this.shehiaList.filter((res) => res.DistrictID == e);
    this.selectedDistrictId = e;
  }

  shehiaChange(e) {
    this.selectedShehiaId = e;
  }
  shehia() {
    const data = {
      requestType: "SHEHIA",
    };
    this.spinner.show();
    this.utilities.postServiceCallNew(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.code == 2000) {
          this.shehiaList = response.data;
        } else {
          this.toastr.error(response.message, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  getRegion() {
    const data = {
      requestType: "REGION_LIST",
    };
    this.spinner.show();
    this.utilities.postServiceCallNew(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.code == 2000) {
          this.regionList = response.data;
        } else {
          this.toastr.error(response.message, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  getDistricts() {
    const data = {
      requestType: "DISTRICT_LIST",
    };
    this.spinner.show();
    this.utilities.postServiceCallNew(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.code == 2000) {
          this.districtList = response.data;
        } else {
          this.toastr.error(response.message, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }
}

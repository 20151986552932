import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { SharedClassComponent } from "src/app/configurations/shared-class/shared-class.component";

@Component({
  selector: "app-maternity-employer-endorsement-list",
  templateUrl: "./maternity-employer-endorsement-list.component.html",
  styleUrls: ["./maternity-employer-endorsement-list.component.scss"],
})
export class MaternityEmployerEndorsementListComponent
  extends SharedClassComponent
  implements OnInit
{
  employerList: any = [];
  filterEmployerForm: FormGroup;
  sectorList: any = [];
  districtList: any = [];
  regionList: any = [];
  natureBusinessList: any = [];
  districtListNew: any = [];
  title = "MATERNITY EMPLOYER'S ENDORSEMENT";
  titleList = "Maternity employer's endorsement list";
  titleCreate = "Create Notification";
  statusList = [
    // {val:1,name:"Employer Number"},
    { val: 1, name: "Active" },
    { val: 2, name: "InActive" },
  ];
  filterCriteria = [
    // {val:1,name:"Employer Number"},
    { val: 6, name: "Region" },
    { val: 2, name: "District" },
    { val: 3, name: "Sector" },
    { val: 5, name: "Registration Date" },
  ];

  showRegion = false;
  showDistrict = false;
  showSector = false;
  showNOB = false;
  showRegDate = false;
  showButton = false;
  showEmployerNo = false;
  shehiaListNew: any;
  selesctedRegion: any;
  selesctedRegionR: any;
  selectedDistrict: any;
  selectedDistrictR: any;
  selectedSector: any;
  selectedSectorR: any;
  selectedRegistrationDate: any;
  shehiaList: any;

  showLoadEmployerDetails: boolean;
  employerButton: any;
  memberButton: any;
  selectedEmployerNo: any;
  EmployerDetailsDatasource: any;
  inputTimer: any;

  employerNumberSearch = false;
  employerNameSearch = false;
  showOtherDetails: boolean = false;
  acronomy: boolean = false;
  alternativeName: boolean = false;
  searchKeyDataSourceEmployer = [
    { val: 1, name: "Employer Number" },
    { val: 2, name: "Employer Name" },
    { val: 3, name: "Acronomy" },
    { val: 4, name: "Alternative Name" },
  ];
  showEmployerSearchValue: boolean;
  searchFormEmployer: FormGroup;
  endorsementForm: FormGroup;
  isEmployerNoObtainedFromSearch: boolean;
  showCreateEndorsementPopUp: boolean = false;
  isFromCreateEmployer: boolean = false;
  showLoadmemberDetails = false;

  searchFormMember: FormGroup;
  memberNumberSearch = false;
  memberNameSearch = false;
  firstNameSearch = false;
  middleNameSearch = false;
  lastNameSearch = false;

  allowFillingOtherDetails = false;
  fetchForm: FormGroup;
  memberInfoSearchResult: any = [];
  memberNumberButton: any;
  showAlertDialogSearchMember = false;
  searchKeyMemberDataSource = [
    { val: 1, name: "Member Number" },
    { val: 2, name: "Full Name" },
    { val: 3, name: "First Name" },
    { val: 4, name: "Middle Name" },
    { val: 5, name: "Last Name" },
    { val: 6, name: "Employer Number" },
    { val: 7, name: "Mothers Name" },
  ];
  mothersNameSearch: boolean = false;
  showMemberSearch: boolean;
  isRowRemoved: boolean = false;
  showRemarksPopUp: boolean = false;
  isMemberFromPopUp: boolean = false;
  remarkPostForm: FormGroup;
  originalDatasourceModified: any = [];
  memberDetailsDatasource: any;
  ngOnInit() {
    this.employerButton = {
      icon: "search",
      type: "default",
      onClick: () => {
        this.isFromCreateEmployer = true;
        this.showLoadEmployerDetails = true;
      },
    };

    this.memberButton = {
      icon: "search",
      type: "default",
      onClick: () => {
        this.searchFormMember.reset();
        this.memberInfoSearchResult = [];
        this.showMemberSearch = false;
        this.isMemberFromPopUp = false;
        this.showLoadmemberDetails = true;
      },
    };
    this.endorsementForm = new FormGroup({
      employerNo: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),

      firstName: new FormControl("", Validators.compose([Validators.required])),
      middleName: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      lastName: new FormControl("", Validators.compose([Validators.required])),
      memberNo: new FormControl("", Validators.compose([Validators.required])),
    });
    this.filterEmployerForm = new FormGroup({
      empNo: new FormControl("", Validators.compose([])),
    });

    this.searchFormMember = new FormGroup({
      searchValue: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      searchCretiliaValue: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
    });

    this.searchFormEmployer = new FormGroup({
      searchValue: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      searchCretiliaValue: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
    });

    this.filterEmployers();
  }

  onInputChangeMember(event: any) {
    let inputValue = event.trim();

    clearTimeout(this.inputTimer);
    this.inputTimer = setTimeout(() => {
      if (inputValue == "") {
        return;
      }
      this.handleSearchMember(inputValue);
    }, 2000);
  }
  selectedSearchCretiria(e) {
    this.showMemberSearch = true;
    if (e.value == 1) {
      this.searchFormMember.get("searchValue").reset();
      this.memberNumberSearch = true;
    } else {
      this.memberNumberSearch = false;
    }

    if (e.value == 2) {
      this.searchFormMember.get("searchValue").reset();
      this.memberNameSearch = true;
    } else {
      this.memberNameSearch = false;
    }

    if (e.value == 3) {
      this.searchFormMember.get("searchValue").reset();
      this.firstNameSearch = true;
    } else {
      this.firstNameSearch = false;
    }

    if (e.value == 4) {
      this.searchFormMember.get("searchValue").reset();
      this.middleNameSearch = true;
    } else {
      this.middleNameSearch = false;
    }

    if (e.value == 5) {
      this.searchFormMember.get("searchValue").reset();
      this.lastNameSearch = true;
    } else {
      this.lastNameSearch = false;
    }

    if (e.value == 6) {
      this.searchFormMember.get("searchValue").reset();
      this.employerNumberSearch = true;
    } else {
      this.employerNumberSearch = false;
    }
    if (e.value == 7) {
      this.searchFormMember.get("searchValue").reset();
      this.mothersNameSearch = true;
    } else {
      this.mothersNameSearch = false;
    }
  }

  previewDetailsMemberSelected(e) {
    this.isMemberFromPopUp = true;

    this.endorsementForm.get("memberNo").setValue(e.data.MemberNumber);
    this.showLoadmemberDetails = false;
  }

  handleSearchMember(inputValue) {
    if (this.memberNameSearch) {
      this.data = {
        requestType: "SEARCH_MEMBERS_LIST",
        fullName: inputValue.trim(),
        mothersName: "",
        memberNumber: "",
        employerNumber: "",
        firstName: "",
        middleName: "",
        surname: "",
      };
    }

    if (this.memberNumberSearch) {
      this.data = {
        requestType: "SEARCH_MEMBERS_LIST",
        fullName: "",
        mothersName: "",
        memberNumber: inputValue.trim(),
        employerNumber: "",
        firstName: "",
        middleName: "",
        surname: "",
      };
    }

    if (this.firstNameSearch) {
      this.data = {
        requestType: "SEARCH_MEMBERS_LIST",
        fullName: "",
        mothersName: "",
        memberNumber: "",
        employerNumber: "",
        firstName: inputValue.trim(),
        middleName: "",
        surname: "",
      };
    }

    if (this.mothersNameSearch) {
      this.data = {
        requestType: "SEARCH_MEMBERS_LIST",
        fullName: "",
        mothersName: inputValue,
        memberNumber: "",
        employerNumber: "",
        firstName: "",
        middleName: "",
        surname: "",
      };
    }

    if (this.middleNameSearch) {
      this.data = {
        requestType: "SEARCH_MEMBERS_LIST",
        fullName: "",
        mothersName: "",
        memberNumber: "",
        employerNumber: "",
        firstName: "",
        middleName: inputValue.trim(),
        surname: "",
      };
    }

    if (this.lastNameSearch) {
      this.data = {
        requestType: "SEARCH_MEMBERS_LIST",
        fullName: "",
        mothersName: "",
        memberNumber: "",
        employerNumber: "",
        firstName: "",
        middleName: "",
        surname: inputValue.trim(),
      };
    }
    if (this.employerNumberSearch) {
      this.data = {
        requestType: "SEARCH_MEMBERS_LIST",
        fullName: "",
        mothersName: "",
        memberNumber: "",
        employerNumber: inputValue.trim(),
        firstName: "",
        middleName: "",
        surname: "",
      };
    }
    this.spinner.show();
    this.utilities.postServiceCallNew(this.data).subscribe(
      (ress) => {
        const response = ress.json();
        if (response.code == 2000) {
          this.memberDetailsDatasource = response.data;

          //this.searchFormMember.reset()
        } else {
          this.toastr.error(response.message, "Error");
        }
      },
      (error) => {
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  performSearch(valueTobeSeaarched: string) {
    const data = {
      requestType: "MEMBER_FILTER",
      keyText: valueTobeSeaarched,
    };
    this.spinner.show();
    this.utilities.postServiceCallNew(data).subscribe(
      (ress) => {
        this.spinner.hide();
        const response = ress.json();
        if (response.code == 2000) {
          this.memberDetailsDatasource = response.data;
        } else {
          this.toastr.error(response.message, "Error");
        }
      },
      (error) => {
        this.toastr.error(error, "Error");
        // Add your spinner handling code here (e.g., this.spinner.hide();)
        // this.distributedName = 'Something went wrong';
      }
    );
  }

  checkSelectedCriteria(e) {
    if (e.value == 1) {
      this.filterEmployerForm.reset();
      this.showEmployerNo = true;
      this.showButton = true;
    } else {
      this.showEmployerNo = false;
    }

    if (e.value == 2) {
      this.filterEmployerForm.reset();
      this.showDistrict = true;
      this.showButton = true;
    } else {
      this.showDistrict = false;
    }

    if (e.value == 3) {
      this.filterEmployerForm.reset();
      this.showSector = true;
      this.showButton = true;
    } else {
      this.showSector = false;
    }

    if (e.value == 4) {
      this.filterEmployerForm.reset();
      this.showNOB = true;
      this.showButton = true;
    } else {
      this.showNOB = false;
    }

    if (e.value == 5) {
      this.filterEmployerForm.reset();
      this.showRegDate = true;
      this.showButton = true;
    } else {
      this.showRegDate = false;
    }
    if (e.value == 6) {
      this.filterEmployerForm.reset();
      this.showRegion = true;
      this.showButton = true;
    } else {
      this.showRegion = false;
    }
  }

  filterStetement() {
    const serchedValue = `${this.filterEmployerForm.get("empNo").value}`.trim();

    if (
      serchedValue == null ||
      serchedValue == "" ||
      serchedValue == undefined ||
      serchedValue.length < 1
    ) {
      this.searchFormEmployer.reset();
      this.EmployerDetailsDatasource = [];
      this.isFromCreateEmployer = false;
      this.showLoadEmployerDetails = true;
      return;
    }
    this.getFullEmployerNo();
  }

  previewEmployerDetails(e) {
    if (this.isFromCreateEmployer) {
      this.selectedEmployerNo = e.data.EmployerNumber;
      this.endorsementForm.get("employerNo").setValue(this.selectedEmployerNo);
      this.isFromCreateEmployer = false;

      this.showLoadEmployerDetails = false;
    } else {
      this.selectedEmployerNo = e.data.EmployerNumber;
      this.filterEmployerForm.get("empNo").setValue(this.selectedEmployerNo);
      this.isFromCreateEmployer = false;
      this.filterEmployers();
      this.showLoadEmployerDetails = false;
    }
  }

  getFullEmployerNo() {
    const data = {
      requestType: "EMPLOYERS_GET",
      employerNumber: `${this.filterEmployerForm.get("empNo").value}`.trim(),
    };
    this.spinner.show();
    this.utilities.postServiceCallNew(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.code == 2000) {
          if (!response.data) {
            return;
          }
          this.filterEmployerForm
            .get("empNo")
            .setValue(response.data.EmployerNumber);
          this.filterEmployers();
        } else {
          this.toastr.error(response.message, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  getFullEmployerNoOnCreate() {
    const data = {
      requestType: "EMPLOYERS_GET",
      employerNumber: `${this.endorsementForm.get("employerNo").value}`.trim(),
    };
    this.spinner.show();
    this.utilities.postServiceCallNew(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.code == 2000) {
          if (!response.data) {
            return;
          }
          this.endorsementForm
            .get("employerNo")
            .setValue(response.data.EmployerNumber);

          this.getFullMemberNo();
        } else {
          this.spinner.hide();
          this.toastr.error(response.message, "Error");
        }
       
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  getFullMemberNo() {
    const data = {
      requestType: "MEMBER_FILTER",
      keyText: `${this.endorsementForm.get("memberNo").value}`.trim(),
    };
    this.spinner.show();
    this.utilities.postServiceCallNew(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.code == 2000) {
          const memNo =
            Object.keys(response.data).length > 0
              ? response.data[0].memNo
              : null;

          if (memNo) {
            this.endorsementForm.get("memberNo").setValue(memNo);
            this.finalSubmit();

            return;
          } else {
            this.spinner.hide();
            this.toastr.error("Failed to get Member No", "Error");
          }
        } else {
          this.spinner.hide();
          this.toastr.error(response.message, "Error");
        }
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }
  filterEmployers() {
    const data = {
      requestType: "MATERNITY_EMPLOYER_NOTIFICATION_ONLINE_LIST",
      employerNo: this.filterEmployerForm.get("empNo").value,
    };
    this.employerList = [];
    this.spinner.show();
    this.utilities.postServiceCallNew(data).subscribe(
      (res) => {
        const response = res.json();
        this.spinner.hide();
        if (response.code == 2000) {
          this.toastr.success(response.message);
          this.employerList = response.data;
        } else {
          this.toastr.error(response.message);
        }
        this.spinner.hide();
      },
      (error) => {
        this.toastr.error("Something went wrong please try again.");
        this.spinner.hide();
      }
    );
  }

  onInputChangeEmployer(event: any) {
    const newValue = event.target.value.trim();

    clearTimeout(this.inputTimer);
    this.inputTimer = setTimeout(() => {
      if (newValue == "") {
        return;
      }
      this.handleSearchEmployer(newValue);
    }, 2000);
  }

  selectedSearchCretiriaEmployer(e) {
    this.showEmployerSearchValue = true;
    this.employerNumberSearch = false;
    this.employerNameSearch = false;
    this.acronomy = false;
    this.alternativeName = false;

    if (e.value == 1) {
      this.searchFormEmployer.get("searchValue").reset();
      this.employerNumberSearch = true;
    }

    if (e.value == 2) {
      this.searchFormEmployer.get("searchValue").reset();
      this.employerNameSearch = true;
    }

    if (e.value == 3) {
      this.searchFormEmployer.get("searchValue").reset();
      this.acronomy = true;
    }

    if (e.value == 4) {
      this.searchFormEmployer.get("searchValue").reset();
      this.alternativeName = true;
    }

    //employerNameSearch
  }
  handleSearchEmployer(e) {
    if (this.employerNameSearch) {
      this.data = {
        requestType: "COMPENSATION_CLAIM_SEARCH_EMPLOYERS",
        employersName: "%" + e + "%",
        employerNumber: "",
        acronomy: "",
        alternativeName: "",
      };
    }

    if (this.employerNumberSearch) {
      this.data = {
        requestType: "COMPENSATION_CLAIM_SEARCH_EMPLOYERS",
        employerNumber: "%" + e + "%",
        acronomy: "",
        alternativeName: "",
      };
    }

    if (this.alternativeName) {
      this.data = {
        requestType: "COMPENSATION_CLAIM_SEARCH_EMPLOYERS",
        employerNumber: "",
        employersName: "",
        acronomy: "",
        alternativeName: "%" + e + "%",
      };
    }

    if (this.acronomy) {
      this.data = {
        requestType: "COMPENSATION_CLAIM_SEARCH_EMPLOYERS",
        employerNumber: "",
        employersName: "",
        acronomy: "%" + e + "%",
        alternativeName: "",
      };
    }

    this.spinner.show();

    this.utilities.postServiceCallNew(this.data).subscribe(
      (res) => {
        const response = res.json();
        if (response.code == 2000) {
          const firstLetter = e.substring(0, 1);
          const data = this.sortByLetter(response.data, firstLetter);
          this.EmployerDetailsDatasource = data;

          //this.searchFormEmployer.reset()
        } else {
          this.toastr.error(response.message, "Error");
        }
      },
      (error) => {
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  sortByLetter(employers, letter) {
    employers.sort((a, b) => {
      const employerNameA = a.EmployerName.toLowerCase();
      const employerNameB = b.EmployerName.toLowerCase();

      if (
        employerNameA.startsWith(letter.toLowerCase()) &&
        !employerNameB.startsWith(letter.toLowerCase())
      ) {
        return -1;
      } else if (
        !employerNameA.startsWith(letter.toLowerCase()) &&
        employerNameB.startsWith(letter.toLowerCase())
      ) {
        return 1;
      } else {
        if (employerNameA < employerNameB) return -1;
        if (employerNameA > employerNameB) return 1;
        return 0;
      }
    });

    return employers;
  }

  submitEndorsement() {
    if (this.endorsementForm.invalid) {
      this.toastr.error("Please Fill all of the fields", "Error");
      return;
    }

    this.getFullEmployerNoOnCreate();
  }

  finalSubmit() {
    this.data = {
      requestType: "MATERNITY_EMPLOYER_NOTIFICATION_ONLINE",
      employerNo: this.endorsementForm.get("employerNo").value,
      memberFirstName: this.endorsementForm.get("firstName").value,
      memberMiddleName: this.endorsementForm.get("middleName").value,
      memberLastName: this.endorsementForm.get("lastName").value,
      memberNo: this.endorsementForm.get("memberNo").value,
    };
    this.spinner.show();
    this.utilities.postServiceCallNew(this.data).subscribe(
      (res) => {
        const response = res.json();
        if (response.code == 2000) {
          this.toastr.success(response.message, "Success");
          this.showCreateEndorsementPopUp = false;
          this.filterEmployers();
        } else {
          this.toastr.error(response.message, "Error");
        }
      },
      (error) => {
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  printReport() {
    let popUpWindow;

    const innerContents = document.getElementById("report-container").innerHTML;
    popUpWindow = window.open(
      "",
      "_blank",
      "scrollbars=no, menubar=no, toolbar=no, location=no, status=no, titlebar=no"
    );
    // popUpWindow.document.open();
    popUpWindow.document.write(`<html lang="en">
             <head>
             <link href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap" rel="stylesheet">
             <style>
             body{
              font-size: 14px;
              line-height: 1.42857143;
              color: #333;
            }
            .space{
              display: flex;
              justify-content: space-between;
              border: 1px dotted #4d88bb;
              padding: 1rem 0;
            }
            .logo__block{
            /* border-bottom: 1px dotted #4d88bb; */
            
            }
            .logo__block p, .footer, p {
              /* color: #4d88bb; */
            }
            .invoice__head__child_contacts{
              margin: 19px;
            }

         
            .invoice_wrapper {
              position: absolute;
            }
            .invoice_wrapper, .invoice{
              /*background-color: rgba(0, 0, 0, 0.055);*/
              padding: 2rem;
              width: fit-content;
              height: 100vh;
            }
            .invoice__head,
            .invoice__how_to_pay{
              display: flex;
              justify-content: space-between;
            }
            .invoice__body{
              display: flex;
              /* justify-content: center; */
            }
            .invoice__body table tr th{
              background: #035817;
              color:white;
              text-align:left;
              vertical-align:center;
            }
            .invoice__body table tr {
              background-color: rgba(136 ,136 ,136, .1);
              border: 1px solid #ddd;
              padding: .35em;
            }
            .logo__block, .footer{
                text-align: center;
            }
            .full_table{
              width: 100%;
            }
            .divide {
              position: relative;
              min-height: 80%;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
            }
            /* .watermark {
            position:fixed;
            font-size: 88px;
            bottom: 0;
            opacity:0.5;
            z-index:99;
            color: #35313126;
            text-align: center;
            } */
            table tbody tr td{
            padding: .5rem;
            }
            td {
              padding: 5px !important; /* Set the height of the table cells */
            }
            
          
            table {
              border-collapse: collapse;
              width: 100%;
              table-layout: auto;
          }
         
          
          th, td {
            border: 1px solid #ddd;
            padding: 8px;
            text-align: center;
            white-space: nowrap; /* Prevent line breaks within cells */
            overflow: hidden; /* Hide content that overflows the cell */
            text-overflow: ellipsis; /* Show an ellipsis (...) when content overflows */
            max-width: 0; /* Allow content to overflow */
    
             
          }
          
          th {
              background-color: #f2f2f2;
          }
          tfoot {
            display: table-footer-group;
          }
  
            @media print {
              thead { display: table-header-group; }
            @page {
              size: A4;
              margin: 0mm;
            }
  
            html, body {
              width: 1024px;
            }
            th:first-child,
            td:first-child {
              width: 10%;
              white-space: nowrap;
            }

            .long-name-cell {
              min-width: 25px; /* Set a minimum width to accommodate longer names */
            }
  
            body {
              margin: 0 auto;
              line-height: 1em;
              word-spacing:2px;
              letter-spacing:0.2px;
              /* font: 14px "Times New Roman", Times, serif; */
              font-size: 17px;
              background:rgba(0, 0, 0, 0.1);
padding-top: 20px;
              color:black;
              width: 100%;
              padding:5px;
              float: none;
            }
            .invoice__body table tr th{
              padding: 5px;
            }
            .footer{
              position: absolute;
              bottom: 10px;
              width: 100%;
              text-align: center;
            }
            /* avoid page-breaks inside a listingContainer*/
            .listingContainer{
              page-break-inside: avoid;
            }
            h1 {
                font: 28px "Times New Roman", Times, serif;
              }
  
              h2 {
                font: 24px "Times New Roman", Times, serif;
              }
  
              h3 {
                font: 20px "Times New Roman", Times, serif;
              }
  
              /* Improve colour contrast of links */
              a:link, a:visited {
                color: #781351
              }
  
              /* URL */
              a:link, a:visited {
                background: transparent;
                color:#333;
                text-decoration:none;
              }
  
              a[href]:after {
                content: "" !important;
              }
              a[href^="http://"] {
                color:#000;
              }
  
              #header {
                height:75px;
                font-size: 24pt;
                color:black
              }
            }
  
             @media print {
              .page-footer-space {
              height: 23px;
             }
.printed-by-info {
                position: fixed;
                bottom: 0;
                width: 98%;
                background-color: white;
                border-top: 1px solid black;
                 
                 
                display: flex;
                justify-content: space-between;
                align-items: center;
              }
            
              .printed-by-info b {
                margin: 0;
              }
            }
  
             </style>
             </head>
             <body onload="window.print()">
             ${innerContents}
             <div class="printed-by-info">
              
         
           </div>
           </body>
         </html>`);
    popUpWindow.document.close();
  }

  onParamsToolBarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: "before",
        widget: "dxButton",
        options: {
          text: "Print",
          type: "default",
          icon: "fa fa-print",
          onClick: this.printReport.bind(this),
        },
      },

      {
        location: "before",
        widget: "dxButton",
        options: {
          text: "Create notification",
          type: "default",

          onClick: this.showCreateNotificationPopUp.bind(this),
        },
      }
    );
  }

  showCreateNotificationPopUp() {
    this.showCreateEndorsementPopUp = true;
  }

  getTotalSum(arrayData: any[], actualItem: string): number {
    if (!arrayData || !actualItem) return 0;
    return arrayData.reduce((total, item) => total + item[actualItem], 0);
  }
}
